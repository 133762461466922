import React from "react";
import serviceData from "../components/data.js";
import TeamCard from "../components/team-card.jsx";

const team = () => {
  const jsonData = serviceData().team;
  return (
    <div className="w-full flex flex-col items-center mt-[17vw] max-sm:mt-[50vw]">
      <h1 className="text-[4vw] font-semibold mb-[0.5vw] max-sm:text-[9vw]">
        Our Team
      </h1>
      <p className="text-[1.2vw] w-[55vw] text-center mb-[4vw] max-sm:text-[3vw]">
        Meet Our Dedicated Team of Environmental Experts: Passionate
        Professionals Committed to Environmental Stewardship and Excellence.
      </p>
      <div className="w-full py-[5vw] flex items justify-center flex-wrap">
        {jsonData.map((record) => (
          <TeamCard
            image={record.image}
            name={record.name}
            position={record.position}
          />
        ))}
      </div>
    </div>
  );
};

export default team;
