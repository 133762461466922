import React, { useState } from "react";
import serviceData from "../components/data.js";
import ProjectCard from "../components/project-card.jsx";
import Cross from "../assets/cross-white.svg";
import Carousel from "../components/Carousel.jsx";

export default function Projects() {
  const jsonData = serviceData().projects;

  const [projectPopUp, setProjectPopUp] = useState(false);
  const setPopUp = (newvalue) => {
    setProjectPopUp(newvalue);
  };
  const closePopUp = () => {
    setProjectPopUp(false);
  };

  const [id, setId] = useState(0);
  const updateId = (newvalue) => {
    setId(newvalue);
  };

  const slides = jsonData[id].images;

  return (
    <div className="w-full flex flex-col items-center mt-[9vw] pb-[6vw] max-sm:mt-[35vw]">
      <h1 className="text-[4vw] font-semibold mb-[0.5vw] max-sm:text-[9vw] max-sm:w-[70vw] max-sm:text-center max-sm:leading-[10vw]">
        Our Recent Projects
      </h1>
      <p className="text-[1.2vw] w-[55vw] text-center mb-[4vw] max-sm:text-[3vw] max-sm:mt-[3vw]">
        Journey Through Environmental Success: A Detailed Look at Our Recent
        Projects and Their Impact on Sustainability.
      </p>
      <div className="w-full flex flex-wrap justify-center max-sm:flex-col max-sm:items-center max-sm:mt-[10vw]">
        {jsonData.map((record) => (
          <ProjectCard
            id={record.id}
            image={record.image}
            title={record.title}
            subtitle={record.subtitle}
            setPopUp={setPopUp}
            setID={updateId}
          />
        ))}
      </div>
      {projectPopUp && <div
        className="w-full h-[100vh] bg-[#000000d5] fixed top-0 z-20 flex flex-col items-center justify-center"
      >
        <div className="w-[60vh] h-[90vh] flex flex-col items-end max-sm:w-[40vh] max-sm:h-fit max-sm:mb-[6vh]">
          <img
            src={Cross}
            onClick={closePopUp}
            className="w-[2vw] h-fit hover:cursor-pointer mb-[1.5vw] max-sm:w-[5vw] max-sm:mb-[4vw]"
            alt="cross"
          />
          <Carousel autoSlide={true}>
            {slides.map((s) => (
              <img
                src={s}
                className="w-[60vh]  object-cover border border-white p-2"
                alt="img"
              />
            ))}
          </Carousel>
        </div>
      </div>}
    </div>
  );
}
