import React from "react";
import { Link } from "react-router-dom";

const Service = (props) => {
  return (
    <div className="w-[27vw] h-[33vw] rounded-[3vw] bg-black mt-[5vw] mx-[2vw] bg-gradient-to-tr from-[#E1FFCC] to-white shadow-card transition duration-300 flex flex-col items-end hover:scale-105 p-[3vw] max-sm:w-[75vw] max-sm:mt-[10vw] max-sm:h-[92vw] max-sm:rounded-[10vw]">
      <img
        src={props.image}
        className="h-[16vw] max-sm:h-[50vw] max-sm:mt-[2vw] max-sm:mr-[2vw]"
        alt=""
      />
      <div className="text-[#386641] w-[21vw] min-h-[29vw] flex flex-col pt-[5vw] justify-between overflow-hidden translate-y-[-18vw] max-sm:w-[60vw] max-sm:min-h-full max-sm:translate-y-[-57vw] max-sm:pt-[20vw] max-sm:mr-[2vw]">
        <h1 className="text-[1.7vw] font-semibold max-sm:text-[5vw]">
          {props.title.toUpperCase()}
        </h1>
        <p className="text-[1.1vw] mt-[.7vw] max-sm:text-[3vw] max-sm:mt-[1.7vw]">
          {props.content}
        </p>
        <Link to={`/detail/${props.path}`}>
          <button className="w-[10vw] h-[4vw] text-white text-[1vw] font-medium bg-[#1b571870] rounded mt-[1.5vw] hover:cursor-pointer hover:bg-[#1b5718ce] transition duration-200 max-sm:w-[30vw] max-sm:h-[14vw] max-sm:text-[2.7vw] max-sm:mt-[4vw]">
            View Details
          </button>
        </Link>
      </div>
    </div>
  );
};

export default Service;
