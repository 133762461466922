import React from "react";
import Form from "../components/Form";
import ContactCard from "../components/ContactCard";
import Data from "../components/data.js";

const Contact = () => {
  const jsonData = Data().contact;
  const email = jsonData[0].subtitle;
  const telephone = jsonData[2].subtitle;
  return (
    <div className="w-full flex flex-col items-center max-sm:mt-[12vw]">
      <h1 className="text-[4vw] font-semibold mb-[0.5vw] max-sm:text-[9vw]">
        Contact Us
      </h1>
      <p className="text-[1.2vw] w-[55vw] text-center mb-[8vw] max-sm:text-[3vw]">
        Connecting for Change: Reach Out to Alpha for Environmental Solutions
        and Collaborative Initiatives.
      </p>

      <Form />

      <div className="flex flex-row w-full justify-center mt-[10vw] max-sm:flex-col max-sm:items-center max-sm:mt-[25vw]">
        <a href={`mailto:${email}`}>
          <ContactCard
            image={jsonData[0].image}
            title={jsonData[0].title}
            subtitle={jsonData[0].subtitle}
          />
        </a>
        <a href="https://maps.app.goo.gl/JU2CvkRgviPJkJac7" target="_blank" rel="noreferrer">
          <ContactCard
            image={jsonData[1].image}
            title={jsonData[1].title}
            subtitle={jsonData[1].subtitle}
          />
        </a>
        <a href={`tel:${telephone}`}>
          <ContactCard
            image={jsonData[2].image}
            title={jsonData[2].title}
            subtitle={jsonData[2].subtitle}
          />
        </a>
      </div>

      <div className="flex justify-center items-center text-[1vw] text-[#013A63] my-[5vw] max-sm:my-[12vw] max-sm:text-[2.7vw]">
        © 2024 All Rights Reserved
      </div>
    </div>
  );
};

export default Contact;
