import React from 'react'

const partner_card = (props) => {
  return (
    <div className='w-[17vw] h-[21vw] mx-[1vw] mb-[3vw] rounded-[2vw] bg-gradient-to-b from-white to-[#A7C95760] shadow-project flex flex-col items-center odd:hover:rotate-2 even:hover:-rotate-2 transition duration-200 max-sm:w-[36vw] max-sm:h-[44vw] max-sm:mx-[3vw] max-sm:rounded-[4vw] max-sm:mb-[7vw]'>
        <div className='w-[10vw] h-[10vw] bg-gradient-to-b from-[#A7C95760] to-white rounded-[5vw] mt-[3vw] max-sm:w-[20vw] max-sm:h-[20vw] max-sm:rounded-[10vw] max-sm:mt-[6vw]'>
            <img src={props.logo} className='w-[9vw] h-[9vw] bg-white rounded-[5vw] m-[0.5vw] max-sm:w-[18vw] max-sm:h-[18vw] max-sm:m-[1vw] max-sm:rounded-[10vw]' alt={props.name} />
        </div>
        <h2 className='text-[1.2vw] font-medium mt-[2vw] max-sm:text-[3vw] max-sm:mt-[4.5vw]'>{props.name}</h2>
    </div>
  )
}

export default partner_card