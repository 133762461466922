import React from "react";
import home_vid from "../assets/home-bg.mp4";

export default function HomePage() {
  return (
    <div className="w-[100vw] h-[100vh] overflow-hidden">
      <video
        src={home_vid}
        autoPlay
        loop
        muted
        className="w-[100vw] h-[100vh] object-cover brightness-50"
      />
      <div className="w-[70vw] h-[100vh] ml-[6.5vw] relative translate-y-[-100vh] z-10 flex flex-col justify-center max-sm:w-full max-sm:pr-[10vw]">
        <div className="flex items-center mt-[3vw]">
          <div className="bg-[#6A994E] h-[1.8vw] w-[.2vw] max-sm:h-[10vw] max-sm:w-[.7vw]"></div>
          <h4 className="text-[#6A994E] font-semibold text-[1.2vw] ml-2 max-sm:text-[4vw]">
            Alpha Statutory
          </h4>
        </div>
        <h1 className="w-[70vw] text-[5vw] leading-[6.5vw] font-semibold my-[2vw] text-white max-sm:text-[13vw] max-sm:leading-[15vw] max-sm:w-full max-sm:my-[5vw]">
          Welcome to Alpha Statutory Compliance Cell
        </h1>
        <p className="text-[1.1vw] leading-[2vw] text-[#ffffffb8] w-[60vw] font-light max-sm:text-[2.5vw] max-sm:leading-[4vw] max-sm:w-[85vw]">
          We, Alpha Statutory Compliance Cell, Pune is one of the unique group
          of expertise team in the field of Environment, EHS regulatory
          monitoring, Project Management, Industrial Safety & Health, Waste management, green belt
          development and it’s compliances.
        </p>
        <button className="w-[12vw] h-[5vw] text-white text-[1.35vw] font-medium bg-gradient-to-b from-[#386641] to-[#70CC82] rounded mt-[3vw] max-sm:w-[27vw] max-sm:h-[12vw] max-sm:mt-[7vw] max-sm:text-[3vw]">
          <a href="#contact">Get Started</a>
        </button>
      </div>
    </div>
  );
}
