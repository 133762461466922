import React from "react";
import serviceData from "../components/data.js";
import ProjectCard from "../components/partner-card";

export default function Partners() {
  const jsonData = serviceData().partners;
  return (
    <div className="w-full flex flex-col items-center mt-[4vw] mb-[6vw] max-sm:mt-[25vw]">
      <h1 className="text-[4vw] font-semibold text-center mb-[0.5vw] max-sm:text-[9vw] max-sm:leading-[10vw] max-sm:mb-[2vw]">
        Clients and Associates
      </h1>
      <p className="text-[1.2vw] w-[55vw] text-center mb-[8vw] max-sm:text-[3vw] max-sm:mb-[16vw]">
        Building Bridges for Environmental Progress: Unveiling Our Collaborative
        Network of Partners in Sustainable Solutions.
      </p>
      <section className="w-full flex flex-wrap justify-center">
        {jsonData.map((record) => (
          <ProjectCard logo={record.logo} name={record.name} />
        ))}
      </section>
    </div>
  );
}
