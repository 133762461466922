import React from "react";

const project_card = (props) => {
  const handleButtonClick = () => {
    props.setPopUp(true);
    props.setID(props.id);
  };

  return (
    <div
      className="w-[27vw] h-[33vw] bg-cover rounded-[1vw] transition mt-[5vw] mx-[2vw] max-sm:w-[75vw] max-sm:h-[91.6vw] max-sm:rounded-[2.2vw] max-sm:mt-[10vw]"
      style={{ backgroundImage: `url(${props.image})` }}
    >
      <div className="w-full h-full flex flex-col justify-end p-[3vw] bg-[#000000a4] hover:backdrop-blur-sm duration-300 rounded-[1vw] max-sm:p-[6.5vw] max-sm:rounded-[2.2vw]">
        <p className="text-[1.1vw] mt-[.7vw] text-white opacity-75 max-sm:text-[3vw] max-sm:mt-[1.5vw] ">
          {props.subtitle}
        </p>
        <h1 className="text-[2.7vw] font-semibold text-white leading-[3.3vw] mt-[.5vw] max-sm:text-[8vw] max-sm:leading-[10vw] max-sm:mt-[1.2vw]">
          {props.title}
        </h1>
        <button
          onClick={handleButtonClick}
          className="w-[10vw] h-[4vw] text-white text-[1vw] font-medium bg-[#A7C95770] rounded mt-[1.5vw] hover:cursor-pointer hover:translate-x-[-0.2vw] hover:translate-y-[-0.2vw] hover:shadow-button transition duration-200 max-sm:w-[30vw] max-sm:h-[14vw] max-sm:text-[2.7vw] max-sm:mt-[4vw]"
        >
          View Details
        </button>
      </div>
    </div>
  );
};

export default project_card;
