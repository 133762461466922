import React from "react";
import BannerImg from "../assets/banner.png";

const Banner = () => {
  return (
    <div className="w-full mt-[20vw] h-[40vw] max-sm:h-[70vw]">
      <div className="w-full h-[23vw] skew-y-3 relative bg-white max-sm:h-[50vw]"></div>
      <div
        className="w-full h-[23vw] -skew-y-3 relative translate-y-[-23vw] bg-cover flex flex-col justify-center items-center text-white max-sm:h-[50vw] max-sm:translate-y-[-50vw]"
        style={{ backgroundImage: `url(${BannerImg})` }}
      >
        <p className="skew-y-3 text-[1.5vw] max-sm:text-[3.3vw]">
          So What's Next?
        </p>
        <h1 className="skew-y-3 text-[3vw] font-semibold max-sm:text-[6vw] text-center w-[80vw]">
          Are You Ready? Let's Get to Work!
        </h1>
        <button className="skew-y-3 w-[10vw] h-[4vw] rounded-[.4vw] bg-white mt-[2vw] text-[#013A63] text-[1.1vw] font-semibold hover:translate-x-[-0.2vw] hover:translate-y-[-0.2vw] hover:shadow-button transition duration-200 max-sm:w-[25vw] max-sm:h-[10vw] max-sm:rounded-[.7vw] max-sm:text-[2.7vw]">
          <a href="#contact">Get Started</a>
        </button>
      </div>
    </div>
  );
};

export default Banner;
