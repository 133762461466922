import React, { useState } from "react";
import toggle from "../assets/toggle.png";
import toggle1 from "../assets/toggle1.png";

export default function NavBar() {
  const [clicked, setClicked] = useState(false);

  function toggleNav() {
    setClicked(!clicked);
  }

  return (
    <>
      <div className="w-[100vw] h-[6vw] bg-gradient-to-b from-[rgb(255,255,255,0.70)] to-[rgb(255,255,255,0.20)] backdrop-blur-md z-[12] flex justify-between items-center pr-[1vw] fixed top-0 max-sm:h-[19vw]">
        <div className="bg-nav-bg h-full w-[31.28vw] bg-cover flex items-center max-sm:w-[85vw] max-sm:bg-nav-bg-sm">
          <h1 className="text-[2vw] bg-gradient-to-r from-[#386641] to-[#70CC82] text-transparent bg-clip-text mt-[1.3vw] ml-[3vw] mb-[1.5vw] max-sm:text-[5vw] max-sm:ml-[10vw] max-sm:mb-[1.5vw] font-bold">
            Alpha Statutory
          </h1>
        </div>
        <img
          src={clicked ? toggle1 : toggle}
          className="hidden h-auto w-[7vw] max-sm:block max-sm:mr-[6vw]"
          alt=""
          onClick={toggleNav}
        />
        <div className="w-[60vw] flex justify-evenly items-center text-[1vw] font-medium hover:cursor-pointer max-sm:hidden">
          <a href="#home">Home</a>
          <a href="#services" className="hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Services</a>
          <a href="#projects">Project</a>
          {/* <a href="#home">CER</a>
          <a href="#home">CSR</a> */}
          <a href="#about">About Us</a>
          <a href="#team">Team</a>
          <a href="#partners">Clients</a>
          <a href="#blog">Blogs</a>
          <button className="w-[7.5vw] h-[3vw] text-white bg-gradient-to-b from-[#386641] to-[#70CC82] rounded">
            <a href="#contact">Get Started</a>
          </button>
        </div>
      </div>

      {/* <div className="absolute top-[5vw] left-[47vw] w-[23vw] h-[40vw] bg-[#ffffff85] backdrop:bl z-30"></div> */}

      <div
        className="w-[75vw] flex flex-col items-center h-[91.5vh] bg-[#ffffff30] backdrop-blur-md fixed top-[19vw] right-0 transition-tra duration-300 z-[11]"
        style={{
          display: clicked ? "flex" : "none",
          transform: clicked ? "translateX(0)" : "translateX(75vw)",
        }}
      >
        <a href="#home">
          <button
            onClick={toggleNav}
            className="w-[65vw] h-[13vw] bg-[#ffffff] mt-[5vw] rounded-md text-[3.5vw] font-semibold"
          >
            Home
          </button>
        </a>
        <a href="#services">
          <button
            onClick={toggleNav}
            className="w-[65vw] h-[13vw] bg-[#ffffff] mt-[5vw] rounded-md text-[3.5vw] font-semibold"
          >
            Services
          </button>
        </a>
        <a href="#projects">
          <button
            onClick={toggleNav}
            className="w-[65vw] h-[13vw] bg-[#ffffff] mt-[5vw] rounded-md text-[3.5vw] font-semibold"
          >
            Projects
          </button>
        </a>

        {/* <a href="#home">
          <button
            onClick={toggleNav}
            className="w-[65vw] h-[13vw] bg-[#ffffff] mt-[5vw] rounded-md text-[3.5vw] font-semibold"
          >
            CER
          </button>
        </a>


        <a href="#home">
          <button
            onClick={toggleNav}
            className="w-[65vw] h-[13vw] bg-[#ffffff] mt-[5vw] rounded-md text-[3.5vw] font-semibold"
          >
            CSR
          </button>
        </a> */}

        <a href="#about">
          <button
            onClick={toggleNav}
            className="w-[65vw] h-[13vw] bg-[#ffffff] mt-[5vw] rounded-md text-[3.5vw] font-semibold"
          >
            About Us
          </button>
        </a>
        <a href="#team">
          <button
            onClick={toggleNav}
            className="w-[65vw] h-[13vw] bg-[#ffffff] mt-[5vw] rounded-md text-[3.5vw] font-semibold"
          >
            Team
          </button>
        </a>
        <a href="#partners">
          <button
            onClick={toggleNav}
            className="w-[65vw] h-[13vw] bg-[#ffffff] mt-[5vw] rounded-md text-[3.5vw] font-semibold"
          >
            Clients
          </button>
        </a>

        <a href="#blog">
          <button
            onClick={toggleNav}
            className="w-[65vw] h-[13vw] bg-[#ffffff] mt-[5vw] rounded-md text-[3.5vw] font-semibold"
          >
            Blogs
          </button>
        </a>

        <a href="#contact">
          <button
            onClick={toggleNav}
            className="w-[65vw] h-[13vw]  text-white bg-gradient-to-b from-[#386641] to-[#70CC82] mt-[5vw] rounded-md text-[4vw] font-semibold"
          >
            Get Started
          </button>
        </a>
      </div>
    </>
  );
}
