import React from "react";
import AboutVideo from "../assets/about-us-video.mp4";

export default function AboutUs() {
  return (
    <div className="flex flex-col  max-sm:mt-[12vw]">
      <h1 className="text-[10vw] font-semibold text-[#000000] text-center mt-[30vw] hidden max-sm:block">
        About Us
      </h1>
      <div className="w-full flex justify-evenly items-center mt-[20vw] max-sm:flex max-sm:flex-col max-sm:gap-[16vw] ">
        <div className="w-[30vw] h-[45vw] max-sm:w-[90vw] max-sm:h-[120vw]">
          <video
            src={AboutVideo}
            autoPlay
            loop
            muted
            className="w-full h-full rounded overflow-hidden relative z-10"
          />
          <div className="w-[30vw] h-[45vw] rounded bg-[#A7C95760] relative rotate-6 translate-y-[-45vw] z-0  max-sm:w-[80vw] max-sm:h-[120vw] max-sm:translate-y-[-120vw] max-sm:translate-x-[5vw] max-sm:rounded-xl"></div>
        </div>

        <div className="w-[35vw] h-[55vw] flex flex-col justify-center max-sm:w-[70vw] max-sm:h-[130vw]">
          <h1 className="text-[4vw] font-semibold text-[#6A994E] max-sm:hidden">
            About Us
          </h1>
          <h2 className="text-[2vw] font-semibold mt-[2vw] text-[#6A994E]  max-sm:text-[5vw] max-sm:font-semibold max-sm:mt-[16vw] max-sm:text-[#000000]">
            Who we are
          </h2>
          <p className="text-[1.1vw] my-[1vw] max-sm:text-[2.7vw]">
            We, Alpha Statutory Compliance Cell, Pune is one of the unique group
            of expertise team in the field of Environment, EHS regulatory
            monitoring, Industrial Safety & Health, Waste management, green belt
            development and it’s compliances. <br />
            Through our strategic guidance and technical expertise, our aim is
            to catalyze a paradigm shift towards sustainable practices,
            fostering societal welfare and preserving our planet's ecological
            integrity.
          </p>
          <h2 className="text-[2vw] font-semibold mt-[2vw] text-[#6A994E]  max-sm:text-[5vw] max-sm:font-semibold max-sm:mt-[10vw] max-sm:text-[#000000]">
            What We Do?
          </h2>
          <p className="text-[1.1vw] max-sm:text-[2.7vw]">
            At Alpha, we specialize in providing comprehensive environmental
            consulting services. Our expertise includes environmental
            assessments, regulatory compliance, also industrial safety
            solutions, identification of hazardous processes, risk analysis,
            risk audits, sustainability consulting, ecological restoration,
            green infrastructure planning, and environmental education and
            outreach. We are dedicated to guiding businesses, governments, and
            communities towards sustainable practices that benefit both people
            and the planet.
          </p>
          <h2 className="text-[2vw] font-semibold mt-[2vw] text-[#6A994E]  max-sm:text-[5vw] max-sm:font-semibold max-sm:mt-[10vw] max-sm:text-[#000000]">
            Our Mission
          </h2>
          <p className="text-[1.1vw] max-sm:text-[2.7vw]">
            Our mission is to provide comprehensive environmental consulting
            services that foster conservation, promote sustainability, prevent
            and mitigate ecological impact. We believe in forging partnerships
            built on trust, integrity, and a shared commitment to environmental
            responsibility.
          </p>
        </div>
      </div>
    </div>
  );
}
