import React from "react";
import ServiceCard from "../components/service-card.jsx";
import serviceData from "../components/data.js";

export default function Services() {
  const jsonData = serviceData().services;
  return (
    <div className="w-full flex flex-col items-center mt-[9vw] pb-[6vw]  max-sm:mt-[25vw] ">
      <h1 className="text-[4vw] font-semibold mb-[0.5vw] max-sm:text-[9vw] max-sm:mb-[2vw] max-sm:w-[60vw] max-sm:text-center max-sm:leading-10">
        Services We Provide
      </h1>
      <p className="text-[1.2vw] w-[55vw] text-center mb-[4vw] max-sm:text-[3vw] max-sm:w-[80] max-sm:[7vw] ">
        Empowering Environmental Excellence: Our Comprehensive Solutions for
        Environmental Excellence and a Sustainable Future
      </p>
      <div className="w-[100vw] flex flex-wrap justify-center max-sm:flex-col max-sm:items-center  ">
        {jsonData.map((record) => (
          <ServiceCard
            image={record.image}
            title={record.title}
            content={record.content}
            path={record.path}
          />
        ))}
      </div>
    </div>
  );
}
