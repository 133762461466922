import React from "react";

const ContactCard = (props) => {
  return (
    <div className="flex flex-row justify-center items-center gap-[2vw] w-[22vw] h-[12vw] mt-[2vw] mx-[2vw] rounded-md bg-[#ffffff85] shadow-card2 hover:shadow-none hover:bg-white hover:translate-x-[-.8vw] hover:translate-y-[.8vw] transition duration-300 hover:cursor-pointer max-sm:w-[55vw] max-sm:h-[30vw] max-sm:gap-[5vw] max-sm:mt-[7vw] max-sm:shadow-card2-sm hover:hover:translate-x-[-1.8vw] max-sm:hover:translate-y-[1.8vw]">
      <img className="h-[4vw] max-sm:h-[11vw]" src={props.image} alt="" />
      <div className="flex flex-col text-[#013A63] w-[8vw] max-sm:w-[20vw]">
        <div className="text-[1.5vw] font-bold max-sm:text-[3.3vw]">{props.title}</div>
        <div className="text-[0.7vw]   max-sm:text-[1.7vw]">{props.subtitle}</div>
      </div>
    </div>
  );
};

export default ContactCard;
