// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBk4G8fS9MYLhkFEUKuj_CZV9fCdtHK9ns",
  authDomain: "alpha-cbb3d.firebaseapp.com",
  projectId: "alpha-cbb3d",
  storageBucket: "alpha-cbb3d.appspot.com",
  messagingSenderId: "5413169172",
  appId: "1:5413169172:web:9a132f91b61d61bac55f01",
  measurementId: "G-C17TJ5L6KX"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

export const auth = getAuth();
export const db = getFirestore(app);
export default app;

