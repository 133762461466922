import React from "react";

const team_card = (props) => {
  return (
    <div className="w-[17vw] h-[23vw] mx-[1.5vw] mb-[3vw] rounded-[3vw] bg-white shadow-project flex flex-col items-center text-center even:hover:rotate-2 odd:hover:-rotate-2 transition duration-200 max-sm:w-[36vw] max-sm:h-[51vw] max-sm:mx-[3vw] max-sm:rounded-[7vw] max-sm:mb-[7vw]">
      <img
        src={props.image}
        className="w-[10vw] h-[10vw] bg-white rounded-[5vw] m-[0.5vw] mt-[3vw] border-[.5vw] border-[#013A63] max-sm:w-[20vw] max-sm:h-[20vw] max-sm:m-[1vw] max-sm:rounded-[12vw] max-sm:mt-[7vw]"
        alt={props.position}
      />
      <h2 className="text-[.9vw] text-[#013A6370] font-bold mt-[1vw] px-[.8vw] max-sm:text-[2.5vw] max-sm:mt-[2vw] max-sm:px-[2vw]">
        {props.position}
      </h2>
      <h1 className="text-[1.4vw] text-[#013A63] text-center leading-[2vw] font-light mt-[.7vw] px-[1.5vw] max-sm:text-[3vw] max-sm:leading-[4vw] max-sm:mt-[2vw] max-sm:px-[3vw]">
        {props.name}
      </h1>
    </div>
  );
};

export default team_card;
