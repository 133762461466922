import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Main from "./pages/main";
import DetailedService from "./pages/detailedService";


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" exact Component={Main} />
        <Route path="/detail/:path" Component={DetailedService} />
        {/* <Route path="/environment" Component={Environment} />
        <Route path="/compliance" Component={Compliance} />
        <Route path="/training" Component={Training} /> */}
      </Routes>
    </Router>
  );
}

export default App;
